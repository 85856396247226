/* src/pages/Home.css */

/* Home container */
.home {
    background-color: transparent;
    
    color: var(--minor-color);
    padding: 2rem 0;
    text-align: center;
    flex: 1;
  }
  
  /* Container for centering content */
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .home .container{
    align-items: normal;
    max-width: 900px;
  }

  .main-box{
    display: flex;
    gap: 4rem;
    margin: 10px auto;
    max-width: 1200px;
    background: transparent;/*linear-gradient(90deg, rgba(227,227,227,1) 5%, rgba(255,255,255,0) 55%);*/
    border-radius: 2rem;
    padding-left: 2rem;
  }



  .mint-box{
    border-radius: 0.5rem;
    background: rgb(205, 202, 202);
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
    padding-top: 4rem;
  }


  .connect-box{
    border-radius: 0.5rem;
    background: radial-gradient(circle, rgba(255,255,255,1) 50%,rgba(255,255,255,0.8) 75%, #8A92A6 100%);/*linear-gradient(90deg, #8A92A6 5%, rgba(255,255,255,1) 65%);*/
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: auto;
    padding: 2rem;
    
  }

  .mint-text-box{
    max-width: 400px;
  }



  .mint-info{
    display: flex;
    justify-content: space-between;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .mint-img-box{
    height: 280px;
    transform: translate(0, -20%);
    
  }

  .mint-img-box img{
    
    max-width: 736px;
    
  }

  .mint-button{
    color: white;
    background-color: rgba(44, 88, 255, 255);
    width: 100%;
    height: 56px;
    border-radius: 0 0 0.5rem 0.5rem;
    border-style: hidden;
    cursor: pointer;
    font-size: large;
  }

  .nftAmountSelector{
    width: 45px;
    height: 25px;
    text-align: center;
    border-radius: 5px;
  }

  .mint-button:hover{
    background-color: rgba(3, 104, 160, 255);
  }

  .connect-button{
    color: white;
    background-color: rgba(44, 88, 255, 255);
    width: 60%;
    height: 56px;
    border-radius: 0.5rem;
    border-style: hidden;
    cursor: pointer;
    font-size: large;
    margin-left: auto;
    margin-right: auto;
  }

  .connect-button:hover{
    background-color: rgba(3, 104, 160, 255);
  }

  .disconnect-link{
    width: 60%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    text-decoration: underline;
  }

  .disconnect-link:hover{
    color:blue;
  }

  /* Heading style */
  h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    color: var(--minor-color);
    text-align: left;
    margin-left: 0px;
  }
  /* Paragraph style */
  p {
    text-align:justify;
    font-size: 1.2rem;
    line-height: 1.5;
  }
  
  @media screen and (max-width: 1000px) {
    .main-box {
      flex-direction: column;
      margin: auto;
      gap: 0rem;
      padding-right: 2rem;
    }
    .mint-img-box{
      height:auto;
      transform: translate(0, 0);
    }
    .mint-img-box img{
      width: 100%;
    }
    .mint-text-box{
      max-width:none;
      
    }
    .connect-box{
      max-width:none;
    }
  }