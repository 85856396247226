
.adaAmounts{
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2rem;  
  margin-bottom: 2rem;
  
}

.selectableAdaAmountBox{
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background: white;
  flex-grow: 1;
  height: 40px;
}

.selectableAdaAmountBox:hover{
  background: rgba(178, 178, 178, 1);
  cursor: pointer;
}

.customAdaAmountBox{
  text-align: center;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  max-width: 100px;
  flex-grow: 1;
}

.customAdaAmountBox:hover{
  background: rgba(178, 178, 178, 1);
  cursor: pointer;
}

.selectedBox{
  background: rgba(44, 88, 255, 1);
  color: white;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

@media screen and (max-width: 1000px) {
  .customAdaAmountBox{
    max-width: 60px;
  }
}