
:root {
  --major-color: rgb(255, 255, 255);/*rgb(39, 30, 54);*/
  --minor-color: rgb(0, 0, 0);/*#beb5ff;*/
  --major-backgroundColor: rgb(255, 255, 255);/*#15101d;*/
  --major-backgroundGradiant: radial-gradient(circle, rgba(255,255,255,1) 76%, rgb(227, 227, 227) 100%);
  --major-backgroundFlatDark: #222E42;
  --main-borderColor: #8a92a6;/*rgb(63, 48, 85);*/

  --type1btn-color: var(--minor-color);
  --type1btn-hoverColor: #8a92a6;/*#d3cff8;*/
  --type1btn-fontColor: #141616;
  --type1btn-hoverFontColor: #141616;

  --type2btn-color: var(--major-color); 
  --type2btn-hoverColor: rgb(0,0,0,0.2);
  --type2btn-fontColor: rgb(0, 0, 0);
  --type2btn-hoverFontColor: black;
  --type2btn-borderColor: var(--main-borderColor);
  
}

#root{
height: 100%;
overflow: auto; 
}

body {
  margin: 0;
  font-family: 'Inter Regular',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--major-backgroundFlatDark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  color: var(--minor-color);
}

a {
  color: var(--minor-color);
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Optional, to prevent scrollbars if content overflows */
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(128, 128, 128,0.5);
  border-radius: 0px 0px 5px 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

